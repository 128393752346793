// frontend/src/components/DynamicComponentLoader.js
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const BASE_URL = 'https://www.webbify.io';
const loadedComponents = {}; // Cache loaded components to avoid re-fetching

// Function to dynamically load a component from S3
export const loadComponentFromS3 = async (componentName, token) => {
  // Ensure componentName has the correct extension
  const componentFileName = componentName.replace(/\.[^/.]+$/, '.js');

  // Check if the component is already loaded
  if (loadedComponents[componentFileName]) {
    console.log(`Component "${componentFileName}" loaded from cache.`);
    return loadedComponents[componentFileName];
  }

  try {
    console.log(`Fetching component "${componentFileName}" from S3 with token.`);
    const response = await axios.get(`${BASE_URL}/component-management/fetch/${componentFileName}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(`Response status for "${componentFileName}":`, response.status);
    console.log(`Response data for "${componentFileName}":`, response.data);

    const componentCode = response.data; // Assuming backend returns the raw JS code as a string
    console.log(`Component code for "${componentFileName}":`, componentCode);

    // Check if the fetched data looks like HTML (which is unexpected)
    if (componentCode.trim().startsWith('<')) {
      console.error(`Fetched component "${componentFileName}" seems to be HTML instead of JS. Possible authentication issue.`);
      return null;
    }

    const blob = new Blob([componentCode], { type: 'application/javascript' });
    const blobUrl = URL.createObjectURL(blob);
    // Dynamically import the component using the blob URL
    console.log(`Importing component "${componentFileName}" from blob URL.`);
    
    // Create a script element to load the IIFE
    const script = document.createElement('script');
    script.src = blobUrl;
    document.body.appendChild(script);

    // Wait for the script to load
    await new Promise((resolve, reject) => {
      script.onload = () => {
        console.log(`Component "${componentFileName}" script loaded.`);
        resolve();
      };
      script.onerror = (err) => {
        console.error(`Error loading script for component "${componentFileName}":`, err);
        reject(err);
      };
    });

    // Access the component from the global variable
    const Component = window.ComponentModule.default || window.ComponentModule;
    if (!Component) {
      console.error(`Component "${componentFileName}" not found on window.ComponentModule.`);
      return null;
    }

    // Clean up
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(script);
    delete window.ComponentModule;

    loadedComponents[componentFileName] = Component;
    return Component;
  } catch (error) {
    console.error(`Error loading component from S3: "${componentFileName}"`, error);
    return null;
  }
};

// Function to register a component dynamically after it has been loaded
export const registerDynamicComponent = (components, setComponents, componentName, Component, designTokens = {}) => {
  const newComponent = {
    id: uuidv4(),
    component: Component,
    name: componentName,
    tokens: designTokens[componentName] || {},
    props: Component.defaultProps || {},
  };

  //components[componentName] = newComponent;
  setComponents(prevComponents => ({
    ...prevComponents,
    [componentName]: newComponent,
  }));
  console.log(`Component "${componentName}" registered dynamically with ID: "${newComponent.id}"`);
};
