// frontend/src/pages/UiBuilder.js
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import '../ui_builder.css';
import { AppstoreOutlined, FolderOutlined } from '@ant-design/icons';
import { components as componentImports } from '../components/ComponentImports';
import { generateUniquePageId, verifyUniquePageIds } from '../utils/pageUtils';
import usePageManagement from '../hooks/usePageManagement';
import { createNewPage } from '../utils/pageCreationUtils';
import { fetchPageContent } from '../utils/fetchPageContentUtils';
import { usePageContentUtils } from '../hooks/usePageContentUtils';
import { useDesignTokenManagement } from '../hooks/useDesignTokenManagement';
import { reconstructFrontendStructureFromBackendSchema } from '../utils/reconstructFrontendStructureFromBackendSchema';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { ReactComponent as DragIcon } from '../assets/icons/drag-icon.svg'; 
import { ReactComponent as JiraIcon } from '../assets/icons/jira-icon.svg'; 

import Header from '../components/Header';
import ComponentSidebar from '../components/ComponentSidebar';
import PageContainer from '../components/PageContainer';
import PropertiesSidebar from '../components/PropertiesSidebar';
import UiBuilderControls from '../components/uiBuilderControls';
import TabbedInterface from '../components/TabbedInterface';
import PagesSidebar from '../components/pagesSidebar';
import { components } from '../components/ComponentImports';
import { TokensContext } from '../contexts/TokensContext';
import SelectionPanel from '../components/SelectionPanel';
import { PageContentContext } from '../contexts/PageContentContext';
import { ComponentPropsContext } from '../contexts/ComponentPropsContext';
import useComponentDrop from '../hooks/useComponentDrop';
import useSavePageContent from '../hooks/useSavePageContent';
import useSavePageContainer from '../hooks/useSavePageContainer';
import GitHubRepoViewer from '../components/gitHub/GitHubRepoViewer';

const UiBuilder = ({ isLoggedIn, setIsLoggedIn }) => {
  const [showComponentSidebar, setShowComponentSidebar] = useState(true);
  const { pageContent, setPageContent, rows, setRows } = useContext(PageContentContext);
  const [selectedComponentInfo, setSelectedComponentInfo] = useState(null);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const { selectedComponentProps } = useContext(ComponentPropsContext);
  const [manualSaveRequested, setManualSaveRequested] = useState(false);
  const { nestedComponents } = useContext(NestedComponentsContext);
  const { savePageContainer } = useSavePageContainer();
  const [viewType, setViewType] = useState('pages');
  const [selectedRepo, setSelectedRepo] = useState(null);

  // Retrieve the token from local storage
  const token = localStorage.getItem('token') || '';

  // Retrieve the userId from the token
  const userId = localStorage.getItem('userId') || '';

  const { 
    designTokens,
    editedTokens,
    globalTokens,
    fetchTokens,
    handleTokenChange,
    updateDesignTokens,
    setEditedTokens,
  } = useDesignTokenManagement(token);

  const [newPageName, setNewPageName] = useState('');

  // hook for usePageManagement
  const { pages, setPages, activePage, setActivePage } = usePageManagement(
    components, 
    globalTokens,
    designTokens, 
    editedTokens, 
    selectedComponentInfo, 
    setSelectedComponentInfo,
    token
  );

  useEffect(() => {
    const fetchAndSetPages = async () => {
      if (token) {
        try {
          // Fetch the page content only if there is no existing content (i.e., on the initial load)
          if (pages.length === 0) {
            const fetchedPages = await fetchPageContent(token, components, designTokens, selectedComponentInfo, setSelectedComponentInfo, editedTokens, setEditedTokens, nestedComponents);
            if (fetchedPages) {
              const validPages = fetchedPages.map(page => ({
                ...page,
                pageName: page.pageName || 'Unnamed Page',
              }));
              setPages(validPages);
              if (validPages.length > 0) {
                setActivePage(validPages[0].pageId);
              }
            }
          }
        } catch (error) {
          console.error('Error fetching page content:', error);
        }
      }
    };

    fetchAndSetPages();
  }, [token, components, designTokens, selectedComponentInfo, setSelectedComponentInfo, editedTokens, setEditedTokens, nestedComponents]);

  // hooks for convertFrontendStructureToBackendSchema, convertPageContentToJSON in usePageContentUtils
  const { convertFrontendStructureToBackendSchema } = usePageContentUtils(components);

  const savePageContent = useSavePageContent(
    token, 
    pages, 
    setPages, 
    activePage, 
    components, 
    designTokens, 
    selectedComponentInfo, 
    setSelectedComponentInfo, 
    editedTokens, 
    setEditedTokens,
    nestedComponents
  );

  // Auto-save functionality
  useEffect(() => {
    const autoSave = setInterval(() => {
      if (activePage) {
        //console.log(`Auto-saving page: ${activePage}`);
        const pageToSave = pages.find(page => page.pageId === activePage);
        if (pageToSave) {
          savePageContent(activePage, pageToSave);
        }
      }
    }, 60000); // Auto-save every 60 seconds

    return () => clearInterval(autoSave);
  }, [activePage, pages, savePageContent]);

  const [openTabs, setOpenTabs] = useState([]);


  const handleSavePageContainer = async () => {
    if (token && userId) {
      const pageContent = {
        rows,
        designTokens,
        editedTokens,
        nestedComponents,
        selectedComponentInfo,
      };

      const filename = `${activePage}.json`; // Use activePage ID as filename

      const url = await savePageContainer(token, userId, filename, pageContent);
      if (url) {
        //console.log('PageContainer content saved at:', url);
      }
    }
  };

  // Call handleSavePageContainer when needed, e.g., on manual save
  const handleManualPageContainerSave = async () => {
    await handleSavePageContainer(); // Save the page container first
    setManualSaveRequested(true); // Set the flag to re-fetch the pages from the backend after saving
  };


  const handleCreateNewPage = (newPageName) => {
    const newPage = createNewPage(
      newPageName, 
      components, 
      designTokens, 
      rows, 
      pages, 
      selectedComponentInfo, 
      setSelectedComponentInfo, 
      editedTokens, 
      setEditedTokens, 
      setPages,
      setActivePage
    );

    // Save the newly created page
    if (newPage) {
      savePageContent(newPage.pageId, newPage);
    }
  };

  const handleRemovePage = (pageId) => {
    if (pages.length <= 1) {
      // Dont remove the last page
      alert("You can't remove the last page.");
      return;
    }

    const updatedPages = pages.filter((page) => page.pageId !== pageId);
    setPages(updatedPages);

    // If the currently selected page is removed, select the first page as active
    if (activePage === pageId) {
      handleSelectPage(updatedPages[0].pageId);
    }
  };

  const handleRenamePage = async (pageIndex, newName) => {
      let updatedPages = [...pages];
      updatedPages[pageIndex].pageName = newName;
      setPages(updatedPages);

      // Save the updated content to backend
      await savePageContent(updatedPages[pageIndex].pageId);
  };

  const handleSelectPage = (pageId) => {
      if (!openTabs.includes(pageId)) {
          setOpenTabs([...openTabs, pageId]);
      }
      setActivePage(pageId);
      const selectedPage = pages.find(page => page.pageId === pageId);
      if (selectedPage && selectedPage.content && selectedPage.content.props) {
          setRows(selectedPage.content.props.rows); // Update rows on page switch
      }
  };

  const handleCloseTab = (pageId) => {
      const newOpenTabs = openTabs.filter(id => id !== pageId);
      setOpenTabs(newOpenTabs);
      // If the closed tab is the active one, switch to another tab (e.g., the first in the new array)
      if (activePage === pageId && newOpenTabs.length > 0) {
          setActivePage(newOpenTabs[0]);
      }
  };

  const handleManualSave = () => {
    // Increment triggerManualSave to trigger the effect
    setManualSaveRequested(true);
  };

  useEffect(() => {
    if (manualSaveRequested) {
      const pageToSave = pages.find(page => page.pageId === activePage);
      if (pageToSave) {
        savePageContent(activePage, pageToSave)
          .then(() => setManualSaveRequested(false)) // Reset the flag after save
          .catch((error) => {
            console.error('Error saving page:', error);
            setManualSaveRequested(false); // Reset the flag if there's an error
          });
      } else {
        console.error('No active page to save');
        setManualSaveRequested(false); // Reset the flag if there's no page to save
      }
    }
  }, [manualSaveRequested, activePage, pages, savePageContent]);


  const handleSwitchSidebar = (sidebar) => {
    setShowComponentSidebar(sidebar === 'component');
    setViewType(sidebar);
  };

  const handleRepoSelect = (repoName) => {
    if (repoName === 'pages') {
      setViewType('pages');
      setSelectedRepo(null);
    } else {
      setViewType('github');
      setSelectedRepo(repoName);
    }
  };

  const handleComponentDragStart = (event, componentName) => {
      event.dataTransfer.setData('componentName', componentName);
      event.dataTransfer.setData('designTokens', JSON.stringify(designTokens[componentName]));
      //console.log('Component being dragged:', componentName);
  };

  // hook for useComponentDrop
  const handleComponentDrop = useComponentDrop(components, token, designTokens);

  useEffect(() => {
    console.log('UiBuilder: designTokens updated:', designTokens);
  }, [designTokens]);

  useEffect(() => {
    console.log('UiBuilder: globalTokens updated:', globalTokens);
  }, [globalTokens]);
  
  useEffect(() => {
      //console.log('UiBuilder - editedTokens updated:', editedTokens);  // Log editedTokens
  }, [editedTokens]);

  useEffect(() => {
      //console.log('selectedComponentInfo updated:', selectedComponentInfo);
  }, [selectedComponentInfo]);

  return (
    <div className="builder-wrap">
      <Header className="mini-main-header" isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <div className="ui-builder-container">
        <div className="vEditLeftSidebar">
          <div className="LeftSideBarTopControls">
            <div className="DragHandler">
              <DragIcon />
            </div>
            {viewType === 'github' ? (
              <button onClick={() => handleSwitchSidebar('github')} className={!showComponentSidebar ? 'active' : ''}>
                Git Files
              </button>
            ) : (
              <button onClick={() => handleSwitchSidebar('pages')} className={!showComponentSidebar ? 'active' : ''}>
                Pages
              </button>
            )}
            <button onClick={() => handleSwitchSidebar('component')}
            className={showComponentSidebar ? 'active' : ''}
            >
              Components
            </button>
          </div>
          {showComponentSidebar ? (
            <ComponentSidebar onDragStart={handleComponentDragStart} designTokens={designTokens} token={token}/>
          ) : viewType === 'pages' ? (
            <PagesSidebar
              pages={pages}
              activePage={activePage}
              openTabs={openTabs}
              setOpenTabs={setOpenTabs}
              onSelectPage={handleSelectPage}
              onRemovePage={handleRemovePage}
              onRenamePage={handleRenamePage}
              onAddPage={handleCreateNewPage}
              newPageName={newPageName}
              setNewPageName={setNewPageName}
              setActivePage={setActivePage}
              savePageContent={savePageContent}
            />
          ) : (
            <GitHubRepoViewer selectedRepo={selectedRepo} />
          )}
        </div>
        <div className="pages-container">
           <TabbedInterface 
            pages={pages} 
            activePage={activePage}
            openTabs={openTabs}
            setOpenTabs={setOpenTabs}
            onSelectPage={handleSelectPage}
            setActivePage={setActivePage}
            handleCloseTab={handleCloseTab}
            onSave={handleManualSave}
            onSavePageContainer={handleManualPageContainerSave}
            viewType={viewType}
            setViewType={setViewType}
            onRepoSelect={handleRepoSelect}
          />
          {selectedComponentInfo && (
            <SelectionPanel 
              selectedComponentInfo={selectedComponentInfo} 
              onClose={() => setSelectedComponentInfo(null)}
            />
          )}
        </div>
        <div className="vEditRightSidebar">
          <div className="RightSideBarTopControls">
            <div className="DragHandler">
              <DragIcon />
            </div>
            <button>Style</button>
            <button className="temp-disabled">Media</button>
            <button className="temp-disabled"><JiraIcon />Jira(5)</button>
          </div>
          <PropertiesSidebar 
            selectedComponentInfo={selectedComponentInfo} 
            globalTokens={globalTokens}
            designTokens={designTokens}
            editedTokens={editedTokens}
            setEditedTokens={setEditedTokens}
            fetchTokens={fetchTokens}
            //onTokenChange={handleTokenChange} 
            updateDesignTokens={updateDesignTokens}
          />
        </div>
      </div>
    </div>
  );
};

export default UiBuilder;