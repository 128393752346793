// frontend/src/hooks/usePageContentUtils.js
export const usePageContentUtils = (components, componentImports) => {
    const convertFrontendStructureToBackendSchema = (rows, nestedComponentsContext) => {
        // Logging the input data
        console.log('convertFrontendStructureToBackendSchema - Input:', JSON.stringify(rows, null, 2));
        console.log('Nested Components Context:', JSON.stringify(nestedComponentsContext, null, 2));
        console.log('convertFrontendStructureToBackendSchema - Input Rows:', rows);
        console.log('convertFrontendStructureToBackendSchema - Nested Components Context:', nestedComponentsContext);
        const convertedData = rows.map(row => ({
            columns: row.columns.map((columnSize, columnIndex) => {
                const components = row.components[columnIndex] || [];
                return {
                    columnSize,
                    components: components.map(component => {
                        const nestedComponentsData = nestedComponentsContext[component.id] 
                            ? Object.entries(nestedComponentsContext[component.id]).map(([nestedColumnId, nestedComponents]) => ({
                                columnId: nestedColumnId,
                                components: nestedComponents.map(nestedComponent => ({
                                    id: nestedComponent.id,
                                    name: nestedComponent.name,
                                    props: nestedComponent.props || {},
                                    tokens: nestedComponent.tokens || {}
                                }))
                            }))
                            : [];

                        return {
                            id: component.id,
                            name: component.name,
                            props: component.props || {},
                            tokens: component.tokens || {},
                            nestedComponents: nestedComponentsData
                        };
                    })
                };
            })
        }));

        // Logging the output data
        console.log('convertFrontendStructureToBackendSchema - Output:', JSON.stringify(convertedData, null, 2));
        return convertedData;
    };

    return { convertFrontendStructureToBackendSchema };
};